/*vertical nav-bar*/
.clr-vertical-nav .nav-trigger,
.clr-vertical-nav .nav-group-trigger {
  background-color: #e8e8e8 !important;
}

.clr-vertical-nav .nav-item:hover {
  background-color: #666666 !important;
}

/*buttons*/
button:focus {
  outline: 1px dotted;
  outline: 5px auto $clr-color-neutral-500;
}

/*buttons hover*/
.btn.btn-info:hover,
.btn-info .btn:hover,
.btn.btn-primary:hover,
.btn-primary .btn:hover {
  background-color: #8c8c8c !important;
  border-color: #8c8c8c !important;
  color: #b2b2b2 !important;
}

.btn.btn-outline:hover,
.btn.btn-outline .btn:hover,
.btn.btn-secondary-outline:hover,
.btn.btn-outline-secondary:hover,
.btn-secondary-outline .btn:hover,
.btn-outline-secondary .btn:hover,
.btn-outline .btn:hover {
  background-color: #b2b2b2 !important;
  color: #8c8c8c !important;
}

.card-block .btn:hover,
.card-footer .btn:hover {
  background-color: #b2b2b2 !important;
  color: #8c8c8c !important;
}

.card-block .btn.btn-link:hover,
.card-footer .btn.btn-link:hover,
.card-block .card-link:hover,
.card-footer .card-link:hover {
  background-color: #b2b2b2 !important;
  color: #8c8c8c !important;
}

.btn, .alert-app-level .alert-item .btn {
  font-weight: 400 !important;
}

/*icons*/
.clr-input-group .clr-input-group-icon-action cds-icon,
.clr-input-group .clr-input-group-icon-action clr-icon {
  fill: #a51417 !important;
}

/* icons color on hover inside buttons */
.btn:hover cds-icon,
.btn:hover clr-icon {
  color: #a51417 !important;
}

.btn-group > .btn:hover cds-icon,
.btn-group > .btn:hover clr-icon {
  color: #a51417 !important; /* Red color for icons on hover in btn-group */
}

/* nav-icons */
.nav-icon:hover {
  fill: #a51417 !important; /* Black icon on hover */
}

.datagrid-table:focus {
  outline: none;
}

.tooltip > .tooltip-content {
  display: none;
}

.tooltip:hover > .tooltip-content,
.tooltip:focus > .tooltip-content {
  display: block;
}

header.header-6,
.header.header-6 {
  background-color: #666666 !important;
}





